import { MessageBarType, getId } from '@fluentui/react';
import {
  BookTimeDialog,
  Button,
  FluentClickHandler,
  Footer,
  Loader,
  Toast,
  ToastProvider,
  buttonStylesStealth,
  loaderStylesSpinnerXLarge,
  useClassNames,
} from '@h2oai/ui-kit';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ILeftPanelProps, LeftPanel } from '../../components/LeftPanel/LeftPanel';
import { Navigation } from '../../components/Navigation/Navigation';
import { NotificationBar } from '../../components/NotificationBar/NotificationBar';
import { EnvService } from '../../services/api';
import { LeftPanelProvider } from '../../utils/contexts';
import { useEnv, useNotification, useUser } from '../../utils/hooks';
import { INotification } from '../../utils/models';
import { RoutePaths, Routes } from '../Routes';
import { IProtectedStyles, protectedStylesDefault } from './Protected.styles';

declare global {
  interface Window {
    dataLayer?: Record<string, unknown>[] | undefined;
  }
}

interface IProtectedClassNames {
  root: string;
  main: string;
  contents: string;
  contentsBody: string;
}

const getMessageBarType = (severity: string): MessageBarType => {
  switch (severity) {
    case 'error':
      return MessageBarType.error;
    case 'warning':
      return MessageBarType.warning;
    case 'blocked':
      return MessageBarType.blocked;
    case 'success':
      return MessageBarType.success;
    case 'severe':
      return MessageBarType.severeWarning;
    default:
      return MessageBarType.info;
  }
};

const addBookTimeNotification = (
  onClickBookTime: FluentClickHandler,
  addNotification: (notification: INotification) => void
) => {
  addNotification({
    id: getId(),
    messageBarType: MessageBarType.warning,
    actions: (
      <div>
        <Button styles={buttonStylesStealth} text="Book a Time" onClick={onClickBookTime} />
      </div>
    ),
    message: `On trial or provisional accounts, H2O advises against uploading and/or using data with personally identifiable
              information (PII) or other sensitive data. Please schedule a call with one of our Activation Specialists to
              learn how to safely use your own data in the H2O AI Cloud.`,
  });
};

const osanoSrc = 'https://cmp.osano.com/6olZmSX1cFRD2MBt/b53c21d4-1b74-4f36-8e09-bf5fb393f98d/osano.js';

export function Protected() {
  const refMain = useRef<HTMLElement>(null),
    location = useLocation(),
    env = useEnv(),
    user = useUser(),
    [osanoIsLoaded, setOsanoIsLoaded] = useState<boolean>(false),
    [leftPanelProps, setLeftPanelProps] = useState<ILeftPanelProps | undefined>(),
    [bookTimeDialogHidden, setBookTimeDialogHidden] = useState<boolean>(true),
    { add: addNotification } = useNotification(),
    onDismissBookTimeDialog = () => setBookTimeDialogHidden(true),
    onClickBookTime = () => setBookTimeDialogHidden(false);

  useEffect(() => {
    const loadNotices = async () => {
      try {
        const { notices } = await EnvService.listNotices({});
        notices.forEach(({ title, severity, content }) =>
          addNotification({
            id: getId(),
            messageBarType: getMessageBarType(severity),
            message: `${title ? `${title}, ` : ''}${content}`,
          })
        );
        if (env?.menu?.hasBookTime) {
          addBookTimeNotification(onClickBookTime, addNotification);
        }
      } catch (error) {}
    };
    loadNotices();
  }, []);
  useEffect(() => {
    if (refMain.current && location.pathname === RoutePaths.APPSTORE) {
      refMain.current.scrollTo(0, 0);
    }
  }, [location.pathname, location.search]);
  useEffect(() => {
    if (!env || !env.osanoEnabled) return;
    if (!document.querySelector(`script[src="${osanoSrc}"`)) {
      const script = document.createElement('script');
      script.src = osanoSrc;
      script.async = true;
      document.body.appendChild(script);
      script.onload = () => setOsanoIsLoaded(true);
    } else {
      setOsanoIsLoaded(true);
    }
  }, [env]);
  const classNames = useClassNames<IProtectedStyles, IProtectedClassNames>('Protected', protectedStylesDefault);
  return !user ? (
    <Loader styles={loaderStylesSpinnerXLarge} label="Loading..." />
  ) : (
    <ToastProvider>
      <LeftPanelProvider setLeftPanelProps={setLeftPanelProps}>
        <div className={classNames.root}>
          <LeftPanel {...leftPanelProps} />
          <Navigation />
          <Toast />
          <main
            data-test="main-content"
            ref={refMain}
            className={leftPanelProps?.content ? `${classNames.main} has-left-panel` : classNames.main}
          >
            <div className={classNames.contents}>
              <NotificationBar />
              <div className={classNames.contentsBody}>
                <Routes />
              </div>
            </div>
            <Footer
              onCookiePreferencesClick={
                osanoIsLoaded ? () => window['Osano'].cm.showDrawer('osano-cm-dom-info-dialog-open') : undefined
              }
              onPrivacyPolicyClick={() => window.open('https://h2o.ai/legal/privacy')}
            />
            <BookTimeDialog
              url={env?.menu?.bookTimeLink}
              onDismiss={onDismissBookTimeDialog}
              hidden={bookTimeDialogHidden}
            />
          </main>
        </div>
      </LeftPanelProvider>
    </ToastProvider>
  );
}
